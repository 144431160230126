<template>
  <page-view title="诉求申请" left-arrow custom-class="page--whiteBg">
    <template #nav-right>
      <yhbh-select v-model="form.yhbh" @confirm="yhbhChangeHandle" :custom-style="{right: '-16px'}"/>
    </template>
    <van-form
      :show-error="true"
      :show-error-message="false"
      :scroll-to-error="true"
      :validate-first="true"
      @submit="onSubmit" @failed="onFailed">

      <van-field
        name="isNil"
        label="非系统户"
        input-align="right">
        <template #input>
          <van-switch v-model="form.isNil" size="20" active-value="1" inactive-value="0" @change="handleIsNilChange"/>
        </template>
      </van-field>
      <van-field
        v-model="form.yhbh"
        name="yhbh"
        label="用户编号"
        placeholder="用户编号"
        :disabled="form.isNil == '1' ? true: false"
        :required="form.isNil != '1' ? true: false"
        :rules="[{ required: true, message: '请填写用户编号' }]"
      >
        <van-button slot="button" size="small" type="primary"
          v-if="form.isNil != '1'" @click.prevent="getUserInfo">
          获取用户信息
        </van-button>
      </van-field>
      <zzjg-select
        v-model="form.jgbmList"
        :sjzzbm="sjzzbm"
        :required="true"
        :disabled="form.isNil != '1'"
        @confirm="zzjgConfirm"/>
      <hsdy-select
          ref="hsdySelect"
          v-model="form.dybmList"
          :jgbm="form.jgbm"
          :required="true"
          :disabled="form.isNil != '1'"
          @confirm="hsdyConfirm"/>
      <van-field
        v-model="form.yhmc"
        name="yhmc"
        required
        label="用户名称"
        placeholder="用户名称"
        :rules="[{ required: true, message: '请填写用户名称' }]"
      />
      <van-field
        name="yhdh"
        required
        v-model="form.yhdh"
        label="联系方式"
        placeholder="联系方式"
        :rules="[{ required: true, message: '请填写联系方式' }]"
      />
      <van-field
        name="yhdz"
        required
        v-model="form.yhdz"
        rows="2"
        autosize
        label="用户地址"
        type="textarea"
        placeholder="用户地址"
        show-word-limit
        :rules="[{required: true, message: '请填写用户地址'}]"
      />


      <vant-datetime-select
        name="sqsj"
        v-model="form.sqsj"
        label="诉求时间"
        placeholder="请选择故诉求时间"
       />
      <vant-select 
        v-model="form.sqlx"
        name="sqlx"
        label="诉求类型"
        placeholder="请选择诉求类型"
        :columns="sqlxList"
      />
      <van-field
        name="bxnr"
        required
        v-model="form.bxnr"
        rows="2"
        autosize
        label="诉求内容"
        type="textarea"
        placeholder="诉求内容"
        show-word-limit
        :rules="[{required: true, message: '请填写诉求内容'}]"
      />
      <van-cell title="附件" class="van-cell--gzdfj">
        <gzd-fj-uploader ref="fjUploader" :gzdxx="gzdxx" :yhbh="form.yhbh" :gnid="gnid" bzsm="附件"/>
      </van-cell>
      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit" :disabled="gzdxx.ywbh?true:false">提交</van-button>
      </div>
    </van-form>
  </page-view>
</template>

<script>
import { mapGetters } from 'vuex';

import YhbhSelect from '@/components/YhbhSelect/index.vue';
import VantSelect from '@/components/VantSelect/index.vue';
import VantDatetimeSelect from '@/components/VantDatetimeSelect/index.vue';
import ZzjgSelect from '@/components/zzjg-select/index.vue';
import HsdySelect from '@/components/hsdy-select/index.vue';
import GzdFjUploader from '@/components/GzdFjUploader';

import {
  zfdmManageService_getZfdmByDmzl
} from '@/api/zfdm'
import { gzdGzsl_add } from '@/api/psdmszhwh/zhwhschema/GzdGzslAPI';
import { yhJbxx_selectiveGetOne } from '@/api/zhcxschema/YhJbxxAPI';
import { khsqService_addKhsqGzdxx } from '@/api/psdmskhfw/service/KhsqServiceAPI';
import {parseTime} from "@/utils";

export default {
  name: 'gzbx',
  components: {
    YhbhSelect,
    VantSelect,
    VantDatetimeSelect,
    ZzjgSelect,
    HsdySelect,
    GzdFjUploader
  },
  data() {
    return {
      form: {
        isNil: '0',
        yhbh: '',
        yhmc: '',
        yhdz: '',
        yhdh: '',

        sqsj: new Date(),
        sqlx: '0',
        bxnr: '',
        jgbmList:[],
        dybmList:[]
      },
      zfdmOptions: {
        'SQLX': [], // 诉求类型
      },
      sjzzbm:'1R',
      gnid:'1402',
      gzdxx:{},
    }
  },
  computed: {
    ...mapGetters(['jgbm','czyh']),
    sqlxList() {
      return this.zfdmOptions['SQLX'].map(item => {
        return {
          label: item.dmnr,
          value: item.dmbh
        }
      })
    }
  },
  methods: {
    zzjgConfirm(value,options){
      if(value && value.length){
        this.form.jgbm = value[value.length-1];
      }
    },
    hsdyConfirm(value,options){
      if(value && value.length){
        this.form.dybm = value[value.length-1];
      }
    },
    yhbhChangeHandle(yhxx) {
      this.form.isNil = '0';
      this.form.jgbm = yhxx.jgbm;
      this.form.dybm = yhxx.dybm;
      this.form.yhbh = yhxx.yhbh;
      this.form.yhmc = yhxx.yhmc;
      this.form.yhdz = yhxx.yhdz;
      this.form.yhdh = yhxx.lxsj || yhxx.lxdh;
      this.form.jgbmList = this.form.jgbm.split('').map((item,index)=>{
        return this.form.jgbm.split('').splice(0,index+1).join('');
      });
      this.form.dybmList = this.form.dybm.split('').map((item,index)=>{
        return this.form.dybm.split('').splice(0,index+1).join('');
      });
      this.sjzzbm = yhxx.jgbm.indexOf('1R')<=-1?'1':'1R';
    },
    handleIsNilChange(val) {
      if (val == '1') {
        this.form.yhbh = '非系统用户';
      } else {
        this.form.yhbh = '';
        this.form.yhmc = '';
        this.form.yhdh = '';
        this.form.yhdz = '';
      }
    },
    resetForm() {
      this.form = {
        isNil: '0',
        yhbh: '',
        yhmc: '',
        yhdz: '',
        yhdh: '',

        sqsj: new Date(),
        sqlx: '0',
        bxnr: ''
      };
    },
    onSubmit(values) {
      this.$toast.loading('正在提交...'),
      console.log('submit', values);

      let params = JSON.parse(JSON.stringify(this.form));
      params.bxslr = "微信公众号";
      params.bxslrid = this.czyh;
      params.gzdh = Date.now();
      params.gzdzt = '0';
      params.sqly = '1';
      //获取当前选中诉求时间
      params.sqsj = values.sqsj;

      let gzdParams = {
        ...JSON.parse(JSON.stringify(params)),
        ywfl:'a',
        ywlb:'a1',
        ywzl:'a11',
        gzdsm:'微信公众号-客户诉求受理',
        gnid:this.gnid,
        sdrid:this.czyh,
      }
      khsqService_addKhsqGzdxx(this.czyh,gzdParams,params).then(res=>{
        if(res.code == 200 && res.content.status == 200){
          this.$toast.success('客户诉求申请受理成功');
          this.gzdxx = res.content.data;
          setTimeout(() => {
            if(this.$refs['fjUploader']){
              this.$refs['fjUploader'].uploadFileList();
            }
          }, 500);
        }else{
          this.$toast(res.content && res.content.message?res.content.message:res.message);
        }
      }).finally(()=>{
        this.$toast.clear();
      })
    },
    onFailed({values, errors}) {
      console.log(this.form);
      console.log('failed:, Errors:', errors)
      this.$notify({ type: 'danger', message: errors[0].message});
    },
    async getUserInfo(){
      if(!this.form.yhbh){
        this.$toast("请输入用户编号后查询");
        return;
      }
      let res = await yhJbxx_selectiveGetOne({
        yhbh:this.form.yhbh
      });
      if(res.code == 200 && res.content){
        this.form.yhmc = res.content.yhmc;
        this.form.yhdz = res.content.yhdz;
        this.form.yhdh = res.content.lxsj || res.content.lxdh;
        this.form.jgbm = res.content.jgbm;
        this.form.dybm = res.content.dybm;
        this.form.jgbmList = this.form.jgbm.split('').map((item,index)=>{
          return this.form.jgbm.split('').splice(0,index+1).join('');
        });
        this.form.dybmList = this.form.dybm.split('').map((item,index)=>{
          return this.form.dybm.split('').splice(0,index+1).join('');
        });
        this.sjzzbm = res.content.jgbm.indexOf('1R')<=-1?'1':'1R';
      }else{
        this.$toast('获取用户信息为空');
      }
    }
  },
  mounted() {
    
    for(let key in this.zfdmOptions) {
      zfdmManageService_getZfdmByDmzl(key).then(response => {
        this.zfdmOptions[key] = response.content;
      });
    }
  }
}
</script>
<style lang="scss" scoped>
  .van-cell--gzdfj /deep/ {
    .van-cell__title {
      flex-basis: 75px;
      flex-grow: 0;
    }
  }
</style>